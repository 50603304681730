<template>
  <div>
    <!-- 显示loading覆盖层 -->
    <van-overlay :show="loading">
      <div class="loading-text">请稍等...</div>
    </van-overlay>
    <div class="tips">
      <div class="load" @click="onload"> 点击获取最新状态!</div>
    </div>
  </div>
</template>

<script>
import { getTgOrderInfo } from "../../api/components";
export default {
  data () {
    return {
      loading: true,
      trade_no: ''
    }
  },
  created () {
    this.trade_no = this.$route.query.trade_no;
    this.$toast.loading({
      duration: 0,
      forbidClick: true,
      message: '正在加载,请稍等...'
    });

  },
  mounted () {
    // 在3秒后调用getTgOrderInfo方法
    setTimeout(() => {
      this.getTgOrderInfo();
    }, 1000);
  },
  methods: {
    onload () {
      window.location.reload()
    },
    async getTgOrderInfo () {
      try {
        const query = {
          trade_no: this.trade_no
        }
        const { data } = await getTgOrderInfo(query);
        // 请求完成后跳转链接
        window.location.href = data.data.link_url;
      } catch (error) {
        console.error('Error fetching order info:', error);
      } finally {
        this.loading = false;
        this.$toast.clear();
      }
    },
  }
}
</script>

<style scoped lang='less'>
.tips {
  font-size: 22px;
  height: 667px;
  font-weight: 700;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .load {
    width: 340px;
    height: 34px;
    line-height: 34px;
    opacity: 1;
    border-radius: 17px;
    background: rgba(255, 105, 18, 1;);
    text-align: center;
    animation: heartbeat 1.5s infinite;
  }
}
@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}
.loading-text {
  color: white;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
</style>
